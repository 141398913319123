import React, { useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { topicContext } from '../../../../pages/_app'
import { contentContext } from '../../../../pages/[locale]/[month]/[day]';
import { notificationCardContext } from '../../../../pages/_app';
import localforage from 'localforage';
import { AnimatePresence } from "framer-motion";
import OtherEvent from './OtherEvent';
import useGetUTM from '../../../customHooks/useGetUTM';
import { AdHome, AdHomeInfinite, TaboolaCategory } from '../../../../src/AdsUnit';
import NotificationCard from '../../../pushNotificationCard/NotificationCard';

const Container = styled.section`
max-width: 600px;
width: 100%;
padding: 28px 14px 0 17px;
`

const OtherEventsWithAdds = React.memo(({ item, locale }) => {
    const { utmSource } = useGetUTM();
    return <div key={item.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginTop: '20px' }}>
            {<div style={{ marginBottom: '20px' }}>
                {AdHomeInfinite(utmSource)}</div>} <OtherEvent item={item} locale={locale} /></div> </div>
}, (props, nextProps) => {
    if (props.item.id === nextProps.item.id) return true;
})


const OtherEvents = () => {
    const { utmSource } = useGetUTM();
    var pageData = useContext(contentContext);
    if (pageData == "") {
        pageData = useContext(topicContext);
    }
    const { handleNotficationCard, showNotificationCard, handleCount, count } = useContext(notificationCardContext);
    const [token, setToken] = useState(null);
    const [notification, setNotification] = useState(false);
    let adunit = 0;
    // console.log(pageData)
    useEffect(() => {
        const tokenCheck = async () => {
            const token = await localforage.getItem('fcm_token')
            setToken(token);
            if (token !== null) {
                handleNotficationCard(false);
            } else {
                if (count < 1) handleNotficationCard(true);

            }
        }

        tokenCheck();
    }, [token])
    useEffect(() => {
        if ('Notification' in window) {
            setNotification(true); // setting notification false
        } else {
            setNotification(false);
        }
    }, [])
    return (
        <Container>
            {pageData.content.map((item, index) => {
                if (!item.featured) {
                    adunit++
                    if (notification) {
                        if (adunit === 3) {
                            // return <div key={item.id}><AnimatePresence>{showNotificationCard && <NotificationCard handleNotficationCard={handleNotficationCard} handleCount={handleCount} />}</AnimatePresence><OtherEvent item={item} locale={pageData.locale} /></div>
                            return <div key={item.id}>
                                {/* <AnimatePresence>
                                    {showNotificationCard && <NotificationCard handleNotficationCard={handleNotficationCard} handleCount={handleCount} />}
                                </AnimatePresence> */}
                                <div key={item.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    {AdHomeInfinite(utmSource)}
                                    {/* {AdCategoryInFeed(utmSource)} */}
                                    {/* {TaboolaCategory(adunit)} */}
                                    <div style={{ marginTop: '20px' }}>
                                        <OtherEvent item={item} locale={pageData.locale} />
                                    </div>
                                </div>
                            </div>
                        }
                    }
                    // if (adunit === 2 || adunit === 5) {
                    //     return <div key={item.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>{AdHome(utmSource)} <div style={{ marginTop: '20px' }}><OtherEvent item={item} locale={pageData.locale} /></div> </div>
                    // }

                    if ((adunit) % 3 === 0 && adunit !== 3) {
                        // console.log("ad position ==>> " + adunit);
                        return <OtherEventsWithAdds item={item} locale={pageData.locale} key={item.id} />
                    }
                    return <OtherEvent key={item.id} item={item} locale={pageData.locale} />
                }
            })}
        </Container>
    )
}

export default OtherEvents
