import React from 'react'
import styled from 'styled-components';
import Head from 'next/head';
import { slugifyContent } from '../../../src/Utility';

const StoryContainer = styled.div`
flex-basis: 100%;
flex : 1;
padding: 10px 17px 10px 21px;
display: flex;
align-items: center;
justify-content: flex-start;
background-color: rgb(250, 250, 250);
border-top: 1px solid rgba(0,0,0,.12);
border-bottom: 1px solid rgba(0,0,0,.12);
overflow-x: scroll;
li{
    list-style: none;
    border-radius: 50%;
    margin-left: 7px;
    height : 60px;
    width : 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid  #A61D29;
}
amp-img {
 border-radius: 50%;
}
a{
    display: flex;
    align-items: center;
    justify-content: center;  
}
`
const StoryCaraousel = ({ pageData }) => {
    let stories = pageData.stories;
    let imgArr = stories.map(story => story.event.cover_image)
    return (<>

        <StoryContainer>
            {pageData.stories.map((story, indx) => <li key={indx}><a href={"/en/stories/amp/" + slugifyContent(story.title) + ".html"}><amp-img layout="fixed" height="50" width="50" src={process.env.ENV_IMAGE_ROOT + imgArr[indx]} /></a> </li>)}
        </StoryContainer>
    </>
    )
}

export default StoryCaraousel
