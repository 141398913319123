export function contentTrim(requiredKeys=[] ,content=[]) {
   
  const filtercontent = [...content].map((item, indx) => {
   let passedObjects = {};
   for (const key in item) {  
       for (var i = 0; i < requiredKeys.length; i++){
           if (requiredKeys[i] === String(key)) {
            passedObjects = Object.assign(passedObjects, { [key]: item[key] })
               
          }
       }
     
        }
        return passedObjects;
    })
   
    return filtercontent;
    
}