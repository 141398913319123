import React from 'react';
import styled from 'styled-components';
import Footer from '../../footer/Footer';
import MainEventCaraousel from '../MainEvent/MainEventCaraousel';
import OtherEventCarusel from '../OtherEvent/OtherEventCarusel';
import StoryCaraousel from '../Stories/StoryCaraousel';
import Nav from './Nav';
import Head from 'next/head';
const Container = styled.main`
display: flex;
align-items: center;
justify-content: center;
`
const Wrapper = styled.section`
max-width : 600px;
flex: 1;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
`
const StrapLine = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex: 1;
flex-basis: 100%;
h5{
 margin: 0;
 text-align: center;
 width: 100%;
 margin-bottom: 8px;
 color: rgba(0,0,0,.5);
 font-family:'Montserrat', sans-serif;
 font-weight: 500;
 font-size: 12px;
 line-height: 24px
}
`

const HomePageAmp = ({ pageData }) => {
  return (<>
    <Head>
      <link href="https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap" rel="stylesheet"></link>
      <link rel="canonical" href={`https://www.thisday.app/en/${pageData.month}/${pageData.day}.html`}></link>
      <script async custom-element="amp-analytics" src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"></script>
    </Head>
    <amp-analytics type="gtag" data-credentials="include">
      <script type="application/json"
        dangerouslySetInnerHTML={{
          __html: `
  {
    "vars" : {
      "gtag_id": "UA-180483140-15",
      "config" : {
        "UA-180483140-15": { "groups": "default" }
      }
    }
  }`}}>
      </script>
    </amp-analytics>
    <Container>
      <Wrapper>
        <Nav day={pageData.day} month={pageData.month} />
        <StoryCaraousel pageData={pageData} />
        <MainEventCaraousel pageData={pageData} />
        <OtherEventCarusel pageData={pageData} />
        <StrapLine>
          <h5>History is best served daily, see you tomorrow!</h5>
        </StrapLine>
        <Footer />
      </Wrapper>
    </Container>
  </>
  )
}

export default HomePageAmp
