import React from 'react'
import styled, { keyframes } from 'styled-components';
const jump = keyframes`{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-15px);
    }
`
const Container = styled.div`
position: fixed;
top : 0;
left : 0;
bottom: 0;
right : 0;
background-color: rgba(0,0,0,.4);
z-index: 222;
.container{
display: flex;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;

}
.circles{
    background-color:#fff;
    border-radius: 50%;
    margin: 2px;
    height: 10px;
    width: 10px;
    animation: ${jump} 0.5s ease-in infinite;
}
.circles:nth-of-type(2){
    animation-delay: .1s;

}
.circles:nth-of-type(3){
    animation-delay: .2s;
}

`
const loadingAnimation = () => {
    return (
        <Container className="max-width">
            <div className="container">
                <div className="circles"></div>
                <div className="circles"></div>
                <div className="circles"></div>
            </div>
        </Container>
    )
}

export default loadingAnimation
