import React, { useContext, useRef } from 'react';
import { contentContext } from '../../../pages/[locale]/[month]/[day]';
import { topicContext } from '../../../pages/_app';
import { AdHome } from '../../../src/AdsUnit'

import styled from 'styled-components';

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import SwiperCore, { Pagination } from 'swiper';
import "swiper/swiper-bundle.css"
import { Swiper, SwiperSlide } from 'swiper/react';
// install Swiper components
SwiperCore.use([Pagination]);

import MainEventCard from './MainEventCard';
import useGetUTM from '../../customHooks/useGetUTM';

const useStyles = makeStyles({
    container: {
        // padding:"29px 20px 0px 20px",   
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    }
})
const MainEventContainer = styled.div`
display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;

.swiper-container{
    padding : 20px 20px;
}
.swiper-pagination {
    bottom: 25px;
    display : ${props => props.pagination ? "block" : "none"}
  
}
.swiper-pagination-bullet {
    border : 2px solid var(--pagiantion-color-active);
    background-color : #FFF;
    opacity : 1;
}
.swiper-pagination-bullet-active{
    background-color :var(--pagiantion-color-active);
}
`
function MainEvent({ isFeatured }) {
    const classes = useStyles();
    const { utmSource } = useGetUTM();
    var pageData = useContext(contentContext);
    if (pageData == "") {
        pageData = useContext(topicContext);
    }
    var paginationShow = useRef(0);
    return (<>
        <Grid item md={12} lg={12} sm={12} xs={12} className={classes.container} >
            <MainEventContainer pagination={paginationShow.current}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={40}
                    pagination={{ clickable: true }}
                >
                    {
                        pageData.content.map(item => {
                            if (item.featured) {
                                paginationShow.current++;
                                return <SwiperSlide key={item.id}><MainEventCard item={item} locale={pageData.locale} /></SwiperSlide>

                            }
                        })
                    }

                </Swiper>
            </MainEventContainer>
        </Grid>
        {/* {isFeatured ? AdHome(utmSource) : null} */}
    </>
    )
}

export default MainEvent
