import React from 'react'
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Link from 'next/link';

// import LinesEllipsis from 'react-lines-ellipsis';
// import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import dayjs from 'dayjs';
import useGetUTM from '../../customHooks/useGetUTM';
var slugify = require('slugify');
import { useRouter } from 'next/router';

const MainEventContainer = styled.div`
box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
border-radius: 8px;
flex : 1;
height : 100%;
`
const ImageWrapper = styled.div`
height : 220px;
.lazy-load-image-background.blur.lazy-load-image-loaded{
    height : 100%;
    width: 100%;
}
.img{
    height : 100%;
    width : 100%;
    border-radius : 8px 8px 0 0 ;
    object-fit : cover;
    object-position: 0px 0px;
}
`
const ContentWrapper = styled.div`
padding : 10px 22px 25px 16px;
.title{

font-family : var(--heading-font);
font-weight : bold;
font-size : 20px;
line-height : 28.8px;
}
.description{
    font-family : var(--text-font);
    font-weight : 500;
    font-size :  var(--text-size-primary);
    line-height : 19.6px;
    padding-top : 8px; 
    color: rgba(0,0,0,.6);
    text-align : justify;
    padding-bottom: 0px;
    display : ${props => props.image ? 'block' : 'flex'};
    align-items : center;
    justify-content : center;
}
.category{
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding-top : 10px;
    position : absolute;
    bottom: 10px;
    width: calc(100% - 19px - 16px);
}
`
const MainEventCard = ({ item, locale }) => {
    let image;
    const { utmAppendString } = useGetUTM();
    // if (item.cover_image) {
    //     image = item.cover_image.url;
    // }

    if (item.cover_image) {
        if (item.cover_image.formats && item.cover_image.formats.medium && item.cover_image.formats.medium.url) {
            image = item.cover_image.formats.medium.url;
        } else {
            image = item.cover_image.url;
        }

    }

    const eventYear = dayjs(item.event_date).year();
    const title = item.event_title;
    const titleForSlug = item.event_title_slug;

    const eventThemes = item.event_themes.map(item => item.theme);
    var eventType;
    try {
        eventType = item.event_types[0].Title;
        if (eventType == 'Event') {
            eventType = 'HAPPENED IN'
        } else if (eventType == 'Birth') {
            eventType = "BORN IN"
        } else if (eventType == 'Death') {
            eventType = "DIED IN"
        }
    } catch {
    }

    const handleThemes = () => {
        return eventThemes.map((item, index) => {
            if (index < eventThemes.length - 1) {
                return item + ", ";
            }
            else {
                return item;
            }
        })
    }
    const themes = handleThemes();

    const link = slugify(titleForSlug, { remove: /[*+-?~.()'"–!:@]/g, lower: true })
    const router = useRouter();
    return (
        <Link href={router.asPath.includes("oppo/category") ? `/oppo/details/${link}` + utmAppendString :
            (router.asPath.includes("realme/category") ? `/realme/details/${link}` + utmAppendString :
                `/${locale}/details/${link}` + utmAppendString)} prefetch={false}>
            <a>
                <MainEventContainer image={item.cover_image}>
                    {item.cover_image !== null ? <ImageWrapper>
                        <LazyLoadImage src={process.env.ENV_IMAGE_ROOT + image} alt={item.cover_image.alternativeText} effect="blur"
                            className="img" />
                    </ImageWrapper> : null}

                    <ContentWrapper>
                        <div style={{ fontSize: '13px', fontWeight: '600', marginBottom: '10px', marginTop: '5px' }}>
                            <span style={{ fontFamily: 'var(--text-font-montserrat)', fontSize: '12px', lineHeight: '24px', fontWeight: 'normal', textTransform: 'uppercase' }}> {eventType} </span>
                            <span style={{ color: "var(--primary-color)", fontFamily: 'var(--heading-font)', fontSize: '12px', lineHeight: '24px', fontWeight: '600' }}>{eventYear}</span>
                            {/* <span style={{ fontFamily: 'var(--heading-font)', color: '#000', fontSize: '10px', padding: '0 4.5px', backgroundColor: '#F5F5F5', textTransform: 'uppercase', fontWeight: '500', border: '1px solid #D9D9D9', borderRadius: '5px', lineHeight: '19px', float: 'right' }}>{themes.map(theme => theme)}</span> */}
                        </div>



                        <div className="title">
                            {item.event_title}
                        </div>
                        <div className="description">
                            {item.event_summary}
                        </div>

                    </ContentWrapper>

                </MainEventContainer>
            </a>
        </Link>
    )
}

export default MainEventCard
