import React, { useContext, useRef } from 'react';
import { contentContext } from '../../../pages/[locale]/[month]/[day]';
import { topicContext } from '../../../pages/_app';
import { AdHome } from '../../../src/AdsUnit'

import styled from 'styled-components';

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import SwiperCore, { Pagination } from 'swiper';
import "swiper/swiper-bundle.css"
import YouTube from 'react-youtube';

// import ReactLivestream from 'react-livestream'

// install Swiper components
SwiperCore.use([Pagination]);

const useStyles = makeStyles({
    container: {
        // padding:"29px 20px 0px 20px",   
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    }
})
const YouTubeContainer = styled.div`
width: 100%;
`
function YouTubeCard({ isFeatured }) {
    const classes = useStyles();
    const opts = {
        height: '360',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    return (<>
        <Grid item md={12} lg={12} sm={12} xs={12} className={classes.container} >
            <YouTubeContainer >
                <YouTube videoId="KnsAFtnM5Zc" opts={opts} />
                {/* <ReactLivestream
                    platform="youtube"
                    youtubeApiKey={'API_KEY_HERE'}
                    youtubeChannelId="UCwMTu04flyFwBnLF0-_5H-w"
                /> */}
            </YouTubeContainer>
        </Grid>
        {/* {isFeatured ? AdHome(utmSource) : null} */}
    </>
    )
}

export default YouTubeCard
