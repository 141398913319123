import React from 'react'
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IoMdNotificationsOutline } from 'react-icons/io';

import { firebaseCloudMessaging } from '../../webpush';
import firebase from 'firebase/app';

import { motion } from 'framer-motion';

import Notifications from 'react-web-notification'

const useStyles = makeStyles({
    cardGrid: {
        overflowX: 'hidden',
        boxShadow: '0px 2px 24px rgba(150,150,150,.25)',
        borderRadius: '8px',
        marginBottom : '22px'
      
    }
})

const NotificationContainer = styled.div`
border-top : 2px solid var(--primary-color);
border-radius : 8px;
height : 146px;
line-height : 19.6px;
position: relative;
.wrapper{
    margin-left : 56px;
    margin-top : 16px;
}

h3{
    font-family : var(--text-font-montserrat);
    font-size : var(--text-size-primary);
    margin : 0;
    margin-bottom : 6px;
}
p{
    margin : 0;
    
}
p:first-of-type{
    margin-bottom : 14px;
}
span:first-of-type{
    color : var(--primary-color);
    font-family : var(--primary-font);
    margin-right : 24px;  

}
@media (max-width : 280px){
 
    span {
        font-size : 14px;

    }
    span:first-of-type{
        margin-right : 20px;
    }
}
`

const NotificationCard = ({ handleNotficationCard, handleCount }) => {
  const [check, setCheck] = React.useState(false);
  const [showcard, setShowcard] = React.useState(true);
    const classes = useStyles();
  async function fireToken() {
    handleNotficationCard(false);
        try {
          const token = await firebaseCloudMessaging.init();
          if (token) {
            getMessage();
          }
        } catch (error) {
          console.log(error);
        }
      }
      function getMessage() {
        const messaging = firebase.messaging();
        // console.log({ messaging });
        messaging.onMessage((message) => {
          const { title, body } = JSON.parse(message.data.notification);
    
          var options = {
            body,
            icon : 'https://www.thisday.app/icons/thisday-logo.svg'
          };
    
          self.registration.showNotification(title, options);
        });
      }
  React.useEffect(() => {
    if (Notification.permission === 'denied') {
      handleNotficationCard(false);
    } else {
      handleNotficationCard(true);
      }
    },[])
    return (
      <motion.div exit={{ scale: .2, transition: { duration: .2 } }} >
        { check && <Notifications
          askAgain={true}
          onPermissionGranted={fireToken}
          onPermissionDenied={()=>{handleNotficationCard(false)}}
          title={''}
     />}
        <Grid item sm={12} xs={12} lg={12} md={12} className={classes.cardGrid}>
                <NotificationContainer>
                    <IoMdNotificationsOutline style={{ position: 'absolute', top : '21px' , left : '14',fontSize : '22px'}}/>
                    <div className="wrapper">
                    <h3>Hey!</h3>
                    <p>Would you like to subscribe for the notification ?</p>
                    <p><span onClick={() => {setCheck(true)}}>I AM IN</span><span onClick={() => { handleNotficationCard(false); handleCount()}}>MAY BE LATER</span></p>
                    </div>
         
               </NotificationContainer>
        </Grid>
        
        </motion.div>
    )
}

export default NotificationCard
