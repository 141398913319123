import Link from "next/link";
import React, { useEffect, useState } from "react";
// import { useAuth } from "src/thisDay/hooks/useAuth";
import styled from "styled-components";
import axios from "axios";
import useGetUTM from "./customHooks/useGetUTM";
import ReactGA from "react-ga";
import { slugifyContent } from "../src/Utility";


const Wrap = styled.div`
display: flex;
flex-wrap: wrap;
margin: 0px 0px 20px;
.pill {
    box-sizing: border-box;
    height: 30px;
    background: #F8F8F8;
    border: 1px solid #C6C6C6;
    border-radius: 26px;
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    padding: 7px 15px;
    margin: 5px 2px;
    cursor: pointer;
}
    .bgimg {
        position: absolute;
        right: 0px;
        margin-top: -55px;
    }
    .selected {
        background: #FEEBE9;
        border: none;
        font-family: var(--text-font);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 127.8%;
        color: #A61D29;
    }
}
`;
const Heading = styled.div`
  font-family: var(--heading-font);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.01em;
  color: #1B1B1B;
`;
const FullWrap = styled.div`
  background: #F8F8F8;
  padding: 10px 10px 0px 10px;
`;
export const ChooseLocation = ({ selected, cb = () => null }) => {
  //   const { user } = useAuth();
  const [locations, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const { utmAppendString, utmSource } = useGetUTM();
  useEffect(() => {
    const getSetPlaces = async () => {
      setLoading(true);
      const res = await getPlaces();
      setLocation(res);
      setLoading(false);
    };
    getSetPlaces();
  }, []);
  return (
    <FullWrap>
      <Heading className="heading">Explore Stories Of Following Places </Heading>
      <Wrap>
        {/* <div className="pill selected">West bengal</div> */}
        {loading ? <div>Loading...</div> : locations.map((l, i) => (
          <Link key={i} href={'/place/' + slugifyContent(`${l}`)}>
            <a onClick={() => {
              ReactGA.event(
                {
                  category: "LocationChip",
                  action: "Selected",
                  label: `${l}`,
                },
                ["opera"]
              );
            }} style={{ zIndex: 1 }}>
              <div className={`pill ${l === selected && 'selected'}`}>{l}</div>
            </a>
          </Link>
        ))}
        <img className="bgimg" src="/chronicles/images/tajmahal.png" />
      </Wrap>
    </FullWrap>
  );
};

const getPlaces = async () => {
  try {
    const locations = await axios.get(
      `https://mobile.thisday.app/stories/hyperlocations`,
      {
        headers: {
          // Authorization: `token ${token}`,
        },
      }
    );
    return locations.data.results;
  } catch (err) {
    // throw new Error(err);
    console.log("Error get places", err);
  }
};