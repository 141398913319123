import React from "react";
import styled from "styled-components";
import Head from "next/head";
import dayjs from "dayjs";
import { slugifyContent } from "../../../src/Utility";
const MainEventContainer = styled.div`
  padding: 21px 17px 10px 21px;
  flex: 1;
  flex-basis: 100%;
  amp-img {
    border-radius: 8px 8px 0 0;
  }
  amp-carousel {
    padding: 8px;
    align-items: stretch;
  }
  a{
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }
  .wrapper {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
    border-radius: 8px;
  }
  .cont-wrapper {
    padding: 10px;
  }
  .theme-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
  }
  .date-container {
    color: #A61D29;
    font-family: "Montserrat", sans-serif;
  }
  .theme-wrapper {
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
    text-transform: uppercase;
  }
  .theme-wrapper p:nth-child(2) {
    font-family: "Montserrat", sans-serif;
    color: #000;
    font-size: 10px;
    padding: 0 4.5px;
    background-color: #f5f5f5;
    text-transform: uppercase;
    font-weight: 500;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    line-height: 19px;
  }
  .main-card {
    display: flex;
    align-items: stretch;
    padding: 10px;
  }
  .event-title {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 28.8px;
  }
  .event-title h3 {
    margin: 0;
  }
  .event-summary {
    font-family: "Noto Serif", serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.6px;
    padding-top: 8px;
    text-align: justify;
  }
`;

const MainEventCaraousel = ({ pageData }) => {
  const content = pageData.content;
  let count = 0;
  const formatedContent = content.map((item, indx) => {
    item.featured && count++;
    let image, height, width;
    if (item.cover_image) {
      if (
        item.cover_image.formats &&
        item.cover_image.formats.medium &&
        item.cover_image.formats.medium.url
      ) {
        image = item.cover_image.formats.medium.url;
        height = item.cover_image.formats.medium.height;
        width = item.cover_image.formats.medium.width;
      } else {
        image = item.cover_image.url;
        height = item.cover_image.height;
        width = item.cover_image.width;
      }
    }
    const eventYear = dayjs(item.event_date).year();
    const title = item.event_title;
    const titleForSlug = item.event_title_slug;

    const eventThemes = item.event_themes.map((item) => item.theme);
    var eventType;
    try {
      eventType = item.event_types[0].Title;
      if (eventType == "Event") {
        eventType = "HAPPENED IN";
      } else if (eventType == "Birth") {
        eventType = "BORN IN";
      } else if (eventType == "Death") {
        eventType = "DIED IN";
      }
    } catch { }

    const handleThemes = () => {
      return eventThemes.map((item, index) => {
        if (index < eventThemes.length - 1) {
          return item + ", ";
        } else {
          return item;
        }
      });
    };
    const themes = handleThemes();
    return {
      image,
      height,
      width,
      title,
      eventYear,
      eventType,
      themes,
      featured: item.featured,
      summary: item.event_summary,
    };
  });
  return (
    <>
      <Head>
        <script
          async
          custom-element="amp-carousel"
          src="https://cdn.ampproject.org/v0/amp-carousel-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-bind"
          src="https://cdn.ampproject.org/v0/amp-bind-0.1.js"
        ></script>
      </Head>
      <MainEventContainer>
        {
          count > 0 && <amp-carousel
            type="slides"
            layout="responsive"
            height="485"
            width="338"
          >
            {formatedContent.map(
              (item, indx) =>
                item.featured && (
                  <div key={indx} className="main-card">
                    <a href={`/en/details/${slugifyContent(item.title)}.amp.html`}>
                      {" "}
                      <div className="wrapper">
                        <div className="img-wrapper">
                          <amp-img
                            layout="responsive"
                            height="220"
                            width="330"
                            src={process.env.ENV_IMAGE_ROOT + item.image}
                          />
                        </div>
                        <div className="cont-wrapper">
                          <div className="theme-wrapper">
                            <p>
                              {item.eventType}{" "}
                              <span className="date-container">
                                {item.eventYear}
                              </span>
                            </p>
                            <p>{item.themes.map((theme) => theme)}</p>
                          </div>
                          <div className="content">
                            <div className="event-title">
                              <h3>{item.title}</h3>
                            </div>
                            <div className="event-summary">
                              <p>{item.summary}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
            )}
          </amp-carousel>}
      </MainEventContainer>
    </>
  );
};

export default MainEventCaraousel;
