import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#E5E5E5" d="M0 0h16v16H0z" />
    <g clipPath="url(#a)">
      <path fill="#fff" d="M-70-128h375v812H-70z" />
      <path
        d="M8 14.667v-2.108"
        stroke="#161630"
        strokeWidth={.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M8 9.899v0a2.714 2.714 0 0 1-2.708-2.72V4.054A2.715 2.715 0 0 1 8 1.334a2.714 2.714 0 0 1 2.708 2.72v3.125A2.714 2.714 0 0 1 8 9.899Z"
        stroke="#161630"
        strokeWidth={.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 7.2c0 2.96-2.387 5.359-5.333 5.359s-5.333-2.4-5.333-5.359"
        stroke="#161630"
        strokeWidth={.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(-70 -128)" d="M0 0h375v812H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
