import React from 'react'
import styled from 'styled-components';
import Head from 'next/head';
const NavBar = styled.nav`
padding: 21px 17px 10px 21px;
flex: 1;
flex-basis: 100%;
display: flex;
align-items: center;
justify-content: space-between;
position: sticky;
top: 0;
z-index: 10;
background-color: #ffff;
font-family: 'Montserrat', sans-serif;
`
const Nav = (props) => {
    let height = 43, width = 94;
    return (<>
        <Head>

        </Head>
        <NavBar>
            <amp-img layout="fixed" height={height} width={width} src="/icons/thisday-logo.svg" />
            <p style={{textDecoration : "underline",textTransform : 'uppercase'}}><span style={{fontWeight : '600'}}>{props.month}</span><span style={{color : '#A61D29',fontWeight : '600'}}>{props.day}</span></p>
        </NavBar>
        </>
    )
}

export default Nav
