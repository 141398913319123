import 'firebase/messaging';
import firebase from 'firebase/app';
import localforage from 'localforage';
import axios from 'axios';
import { getUUID } from './src/Utility';

const firebaseCloudMessaging = {
  //checking whether token is available in indexed DB
  tokenInlocalforage: async () => {
    return localforage.getItem('fcm_token');
  },

  //initializing firebase app
  init: async function () {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyDshXKZEK29DCpEwrCGpWrHBL0zquJmPw4",
        authDomain: "thisday-611b0.firebaseapp.com",
        projectId: "thisday-611b0",
        storageBucket: "thisday-611b0.appspot.com",
        messagingSenderId: "949524595493",
        appId: "1:949524595493:web:cedd61c96ab9fd7b8b50cc",
        measurementId: "G-VY4HSJ35M4"

      });

      try {
        const messaging = firebase.messaging();
        const tokenInLocalForage = await this.tokenInlocalforage();
        let uuid = await getUUID();

        // console.log(uuid);
        // getUUID()
        //   .then(res =>{ uuid = res})
        //   .catch(err => console.log(err));

        //if FCM token is already there just return the token
        if (tokenInLocalForage !== null) {
          return tokenInLocalForage;
        }

        //requesting notification permission from browser
        const status = await Notification.requestPermission();
        if (status && status === 'granted') {
          //getting token from FCM
          const fcm_token = await messaging.getToken();
          if (fcm_token) {
            //setting FCM token in indexed db using localforage
            localforage.setItem('fcm_token', fcm_token);
            //return the FCM token after saving it
            console.log(fcm_token);
            axios.post("https://subscribers.thisday.app/firebase/token-registration", {
              token: fcm_token,
              clientUUID: uuid
            }).then(res => console.log(res)).catch(err => console.log(err))
            return fcm_token;
          }
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  },
};
export { firebaseCloudMessaging };