import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useContext } from 'react';
import { contentContext } from '../../pages/[locale]/[month]/[day]';
import { topicContext } from '../../pages/_app';
import { FiArrowUpRight } from 'react-icons/fi';
import dayjs from 'dayjs';

const DateWrapper = styled.div`
font-family : var(--heading-font);
font-size : 15px;
display: flex;
justify-content: center;
align-items: center;
font-weight : 400;
line-height : 20.86px;
text-decoration : underline;
span{
    font-weight : 600;
    text-transform : uppercase;

    :nth-child(2){
      color : #A61D29 ;
    }
}
button{
  outline : none;
  display:flex;
  justify-content : center;
  align-items : center;
  background-color : transparent;
  font-size : 17px;
  border-radius : 50%;
  border : none;
}
`
const useStyles = makeStyles({
  dateDisplayGrid: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'flex-end',

  }
})
const DateDisplay = ({ handleBottomsheet = () => {} }) => {
  const classes = useStyles();
  var pageData = useContext(contentContext);
  if (pageData == "") {
    pageData = useContext(topicContext);
  }
  var eventDate;
  try {
    eventDate = dayjs(pageData.content[0].event_date, "YYYY-MM-DD");
  } catch {
    eventDate = dayjs(new Date(), "YYYY-MM-DD")
  }
  return (
    <Grid item xs={12} sm={12} md={8} lg={8} className={classes.dateDisplayGrid}>
      <DateWrapper onClick={() => { handleBottomsheet(true) }}><span>{eventDate.format('MMM')}</span> <span>{eventDate.format('DD')}</span><span><button><FiArrowUpRight style={{ backgroundColor: '#A61D29', color: '#fff', borderRadius: '50%' }} /></button></span></DateWrapper>
    </Grid>
  )
}

export default DateDisplay
