import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Grid } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"

import "swiper/swiper-bundle.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedEventCircle from './FeaturedEventCircle';
import { useContext } from 'react';
import { contentContext } from '../../../pages/[locale]/[month]/[day]';
import { topicContext } from '../../../pages/_app'
import { slugifyContent } from '../../../src/Utility';
import useGetUTM from '../../customHooks/useGetUTM';
import styled from "styled-components";
import Event from "../../cms/Event";
import { contentTrim } from "../../../src/contentTrim";
import { AiOutlineReload } from "react-icons/ai";
import { IoChevronForwardCircle, IoChevronBackCircle } from 'react-icons/io5';

const Container = styled.section`
position: relative;
overflow: hidden;
width: 100%;
border-top: 1px solid rgba(0,0,0,.12);
border-bottom: 1px solid rgba(0,0,0,.12);
background-color: rgb(250, 250, 250);
.nav-buttons{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
  }
  .back{
    position: absolute;
    left: 10px;
    top: 29px;
    color: rgba(255,255,255,1);
    font-size: 28px;
  }
  .forward{
    position: absolute;
    right: 10px;
    top: 29px;
    color: rgba(255,255,255,1);
    font-size: 28px;
  }
`
const Section = styled.div`
  width: 100%;
  padding-left: 15px;
  overflow: auto;
  scroll-behavior: smooth;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  --ms-overflow-style: none;
  ul {
    margin: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    padding: 0;
    column-gap: 15px;
  }
  .loading {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    animation: rotate 500ms linear infinite;
    @keyframes rotate{
        from {
            transform: rotate(0);
        }
        to{
            transform: rotate(360deg);
        }
    }
  }
`;



const useStyles = makeStyles({
    featuredEventGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // padding: '10px 9px 0 21px',
        width: '100%',
        position: 'relative',
        backgroundColor: 'rgb(250, 250, 250)'
    }
})

function FeaturedEvent({ handleClose, handleIndex, handleStoriesUrls, loadInfinite }) {
    const { utmAppendString, utmSource } = useGetUTM();

    const [data, setData] = useState([]);
    const [start, setStart] = useState(10);
    const [loading, setLoading] = useState(false);
    const [allfetched, setAllFetched] = useState(false);

    const [windowWidth, setWindowWidth] = useState(0);
    const [scrolledLeft, setScrolledLeft] = useState(0);
    const [width, setWidth] = useState(0);
    const [totalWidth, setTotalWidth] = useState(0);



    var pageData = useContext(contentContext);
    if (pageData == "") {
        pageData = useContext(topicContext);
    }

    let stories = [...data];
    const requiredKeysStories = ["event", "title", "id"];
    let filterstories = contentTrim(requiredKeysStories, stories);

    //removing unsed data in json

    filterstories = filterstories.map((item) => {
        return {
            ...item,
            event: {
                cover_image:
                    item.event.cover_image.formats?.thumbnail?.url ||
                    item.event.cover_image.url,
            },
        };
    });

    let content = [...pageData.stories, ...filterstories];

    useEffect(() => {
        handleStoriesUrls(content)
    }, [data])


    let imgsrc;
    //taking ref and performing logic to keep track of scrollwidth of ref element
    const calculateWidth = useMemo(() => (ele) => {
        const width = ele.getBoundingClientRect().width;
        const scrolledLeft = ele.scrollLeft;
        const totalWidth = ele.scrollWidth;
        setScrolledLeft(scrolledLeft);
        setWidth(width);
        setTotalWidth(totalWidth);


        return ((width + scrolledLeft) / totalWidth) * 100;
    }, [])

    const listRef = useRef(null);
    const fetchFucn = useCallback(async (width) => {
        if (width >= 95) {
            setLoading(true);
            const res = await Event.get(
                "/stories?event_niches.niche=" +
                encodeURIComponent(pageData.category) +
                "&_start=" +
                start +
                "&_limit=10&_sort=id:DESC"
            );
            const resdata = await res.data;
            if (resdata.length === 0) {
                setAllFetched(true);
                setLoading(false);
                return;
            }
            setData((prev) => [...prev, ...resdata]);
            setStart(start + 10);
            setLoading(false);
        }
    }, [start])

    useEffect(() => {
        if (listRef && loadInfinite) {
            const list = listRef.current;
            const scrollFun = async () => {
                const width = calculateWidth(list)

                !allfetched && fetchFucn(width)

            };

            !loading && list.addEventListener("scroll", scrollFun);

            return () => {
                list.removeEventListener("scroll", scrollFun);
            };
        }
    }, [listRef, loading]);

    //this for first time load 
    useEffect(() => {
        const list = listRef.current;
        if (list && loadInfinite) {
            fetchFucn(calculateWidth(list))
        } else {
            calculateWidth(list)
        }
    }, [listRef.current])
    useEffect(() => {
        window.addEventListener("resize", () => setWindowWidth(window.innerWidth))
        setWindowWidth(window.innerWidth);

    }, [])



    const strinArry = [...content].map(JSON.stringify);
    const notstringArry = new Set(strinArry);
    content = Array.from(notstringArry).map(JSON.parse);
    imgsrc = content.map((item) => item.event.cover_image);

    const storyForward = (e) => {
        listRef.current.scrollLeft = listRef.current.scrollLeft + ((600 / 8) * 4); // x(width of screen) = 600 , y(no of stories between x) = 8 , no of stories to move = 4, (x/y) * no of stories to move 
    }
    const storyBack = useCallback((e) => {
        listRef.current.scrollLeft = listRef.current.scrollLeft - ((600 / 8) * 4);

    }, [start])

    return (
        <Container>
            {windowWidth > 1024 && <div className="back"> {scrolledLeft === 0 ? null : <div onClick={storyBack}> <IoChevronBackCircle /></div>}</div>}
            {windowWidth > 1024 && <div className="forward">  {(scrolledLeft + width) / totalWidth * 100 >= 100 ? null : <div onClick={storyForward}> <IoChevronForwardCircle /></div>}

            </div>}
            <Section ref={listRef}>
                <ul>
                    {content.map((item, index) => (
                        <li key={item.id}>
                            <FeaturedEventCircle
                                handleClose={handleClose}
                                src={imgsrc[index]}
                                index={index}
                                handleIndex={handleIndex}
                                link={
                                    (utmSource === "oppobrowser"
                                        ? "/oppo/stories/amp/"
                                        : utmSource === "realmebrowser"
                                            ? "/realme/stories/amp/"
                                            : "/en/stories/amp/") +
                                    slugifyContent(item.title) +
                                    ".html" +
                                    utmAppendString
                                }
                            />
                        </li>
                    ))}
                </ul>
                {loading && loadInfinite &&
                    <li className="loading">
                        <AiOutlineReload />
                    </li>
                }
            </Section>
        </Container>

    );
}

export default FeaturedEvent;
