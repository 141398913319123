import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion';
import Events from '../../../components/cms/Event';
import dynamic from 'next/dynamic';

import Nav from '../../../components/rootPageCompo/Nav';
import MainEvent from '../../../components/rootPageCompo/MainEvent/MainEvent';
import OtherEvents from '../../../components/rootPageCompo/OtherEvents/newUi/OtherEvents';
import FeaturedEvent from '../../../components/rootPageCompo/FeaturedEvent/FeaturedEvent';
import { bottomNavigationContext } from '../../_app'

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../../../components/rootPageCompo/Footer';
import { slugifyContent } from './../../../src/Utility';
// import RoyaltyInPeace from '../../../components/rootPageCompo/SpecialEvent/AuranzebsDeath/RoyaltyInPeace';

// import WomensDayHomepageCard from '../../../components/rootPageCompo/SpecialEvent/WomensDay/WomensDayHomepageCard';


import { NextSeo } from 'next-seo';
import * as SEOConstants from '../../../src/Constants';

import { useRouter } from "next/router";
import { useAmp } from "next/amp";
import ReactGA from "react-ga";

// import BhagatSingh from '../../../components/rootPageCompo/SpecialEvent/BhagatSingh/BhagatSingh';
import PastEventsCard from '../../../components/rootPageCompo/PastEvents/PastEventsCard';
// import Jalianwala from '../../../components/rootPageCompo/SpecialEvent/Jalianwala bagh/Jalianwala';
import CategoryCard from '../../../components/rootPageCompo/Category/CategoryCard';
import Shimmer from '../../../components/shimmerAnimation/Shimmer';
import useGetParams from '../../../components/customHooks/useGetParams';
import useGetUTM from '../../../components/customHooks/useGetUTM';
import { contentTrim } from '../../../src/contentTrim';
import LoadingAnimation from '../../../components/cssAnimations/three-dots-loading/LoadingAnimation';
import HomePageAmp from "../../../amp-components/rootPageCompo/HomePage/HomePageAmp";
import Head from "next/head";
import Stories from '../../../components/rootPageCompo/FeaturedEvent/Stories';
import InfiniteFetch from '../../../components/rootPageCompo/infiniteFetch/InfiniteFetch'

import { AdHome } from '../../../src/AdsUnit';
import YouTubeCard from '../../../components/rootPageCompo/YouTube/YoutTubeCard';
import useScrollPosition from '../../../common/hooks/useScrollPosition';
import MobileDatePicker, { monthMap } from '../../../components/chronicles/components/MobileDatePicker';
import Link from "next/link";
import { ChooseLocation } from '../../../components/ChooseLocation';

var fs = require("fs");
// import FeaturedEventStories from '../../../components/rootPageCompo/FeaturedEvent/FeaturedEventStories';
// import CategoryBottomSheet from '../../../components/rootPageCompo/Category/CategoryBottomSheet';
//dynamic imports
const FeaturedEventStories = dynamic(
  () =>
    import(
      "./../../../components/rootPageCompo/FeaturedEvent/FeaturedEventStories"
    ),
  {
    loading: () => <LoadingAnimation />,
  }
);
const PastEventsBottomSheet = dynamic(
  () =>
    import(
      "./../../../components/rootPageCompo/PastEvents/PastEventsBottomSheet"
    ),
  {
    loading: () => <LoadingAnimation />,
  }
);
const CategoryBottomSheet = dynamic(
  () =>
    import("./../../../components/rootPageCompo/Category/CategoryBottomSheet"),
  {
    loading: () => <LoadingAnimation />,
  }
);

const Strapline = styled.div`
.content{
    text-align: center;
    margin-bottom: 10px;
    p{
        margin : 0;
        font-family : var( --text-font-montserrat );
        font-weight : 500;
        font-size : 12px;
        line-height : 24px;
        color :rgba(0, 0, 0, 0.5);
    }   
}
`

const useStyles = makeStyles({
  mainGrid: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'relative'
  }
})
const checkFeatured = (content = []) => {
  const count = content.filter((x, y, a) => x.featured === true);
  return count.length > 0 ? true : false;
}

//this will return the first event image, featured or normal otherwise
const getFirstEventImageForPageOG = (pageData = []) => {
  let image;
  var continueSearchingFeatured = true;
  var continueSearchingOther = true;
  pageData.content.map((item) => {
    if (item.featured && continueSearchingFeatured) {
      if (item.cover_image) {
        if (
          item.cover_image.formats &&
          item.cover_image.formats.small &&
          item.cover_image.formats.small.url
        ) {
          image = item.cover_image.formats.small.url;
        } else {
          image = item.cover_image.url;
        }
      }
      continueSearchingFeatured = false;
    }
    if (continueSearchingFeatured && continueSearchingOther) {
      if (item.cover_image) {
        if (
          item.cover_image.formats &&
          item.cover_image.formats.small &&
          item.cover_image.formats.small.url
        ) {
          image = item.cover_image.formats.small.url;
        } else {
          image = item.cover_image.url;
        }
      }
      continueSearchingOther = false;
    }
  });
  return image;
};
//amp
// export const config = { amp: "hybrid" };
export const contentContext = React.createContext("");
// export const config = {
//     unstable_runtimeJS: false
//   };
export default function HomePage({ pageData }) {
  // pageData = globalData;
  const { utmSource, utmMedium } = useGetUTM();
  const [storiesUrls, setStoriesUrls] = useState();
  const handleStoriesUrls = (p = []) => {
    const storiesUrls = p.map(story => ({
      url: (utmSource === 'oppobrowser' ? `/oppo/stories/amp/${slugifyContent(story.title)}.html` : (utmSource === 'realmebrowser' ? `/realme/stories/amp/${slugifyContent(story.title)}.html` : `/en/stories/amp/${slugifyContent(story.title)}.html`)),
      image: story.event.cover_image
    }));
    setStoriesUrls(storiesUrls);
  }
  const { isTop } = useScrollPosition();
  const [showDatePicker, setShowDatePicker] = useState();

  const isFeatured = checkFeatured(pageData.content);
  const firstEventImage = process.env.ENV_IMAGE_ROOT + getFirstEventImageForPageOG(pageData);
  const classes = useStyles();
  const bottomNavigation = useContext(bottomNavigationContext);
  useEffect(() => {
    bottomNavigation.handleShowBottomNavigation(true);
  }, [bottomNavigation])

  const [close, setClose] = useState(false);
  const [activeIndx, setActiveIndx] = useState(0);
  const dateLink = useRef(null);

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (selectedDate) {
      dateLink.current?.click?.()
    }
  }, [selectedDate])
  const handleIndex = (indx) => {
    setActiveIndx(indx)
  };
  const handleClose = (val) => {
    setClose(val);
  };
  // state vairable for bottom sheet view true or false
  const [showbottomsheet, setShowbottomsheet] = useState(false);
  const [showCategoryBottomSheet, setShowCategoryBottomSheet] = useState(false);
  const handleCategorySheet = (val) => {
    setShowCategoryBottomSheet(val)
  }
  //Shimmer 
  const [shimmer, setShimmer] = useState(false);
  const router = useRouter();
  // console.log(router.query.locale.month.day)
  const handleShimmer = () => {
    setShimmer(true);
  }
  //montior router change to control shimmer
  useEffect(() => {
    setShimmer(false)
  }, [])
  useEffect(() => {
    const handleRouteStartChange = (url) => {
      // console.log("started")
    }
    const handleRouteChangeCompleteChange = (url) => {
      setShimmer(false);
    }
    router.events.on('routeChangeStart', handleRouteStartChange);
    router.events.on('routeChangeComplete', handleRouteChangeCompleteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteStartChange)
      router.events.off('routeChangeComplete', handleRouteChangeCompleteChange);
    }
  }, [router.events])
  useEffect(() => {
    if (showbottomsheet) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'visible';

    }

  }, [showbottomsheet])
  const handleBottomsheet = (val) => {
    setShowbottomsheet(val);
  }
  //UTM
  const params = useGetParams();

  if (shimmer) return <Shimmer />;

  if (typeof pageData.content !== "undefined" && pageData.content.length > 0) {
    return useAmp() ? (
      <>
        <HomePageAmp pageData={pageData} />
      </>
    ) : (
      <>
        {/* < motion.div initial="hidden" animate="visible" variants={{
                hidden: {
                    scale: 0.8,
                    opacity: 0,
                },
                visible: {
                    x: 0,
                    scale: 1,
                    opacity: 1,
                    transition: {
                        duration: .5
                    }
                },

            }} > */}
        <Head>
          <link rel="amphtml" href={`https://www.thisday.app/en/${pageData.month}/${pageData.day}.amp.html`}></link>
          {/* Taboola Ads */}
          {/* {utmSource === "xiaomicalendar" ?
            <script
              dangerouslySetInnerHTML={{
                __html: `
            window._taboola_home_page_counter = 0;
            window._displayedAdTaboolaHome = window._displayedAdTaboolaHome || { };
            window._taboola = window._taboola || [];
            _taboola.push({homepage:'auto'});
            !function (e, f, u, i) {
              if (!document.getElementById(i)){
                e.async = 1;
                e.src = u;
                e.id = i;
                f.parentNode.insertBefore(e, f);
              }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/ekanktechnologies-thisday-xiaomicalendar/loader.js',
            'tb_loader_script');
            if(window.performance && typeof window.performance.mark == 'function')
              {window.performance.mark('tbl_ic');}
            `,
              }}
            />
            : <script
              dangerouslySetInnerHTML={{
                __html: `
          window._taboola_home_page_counter = 0;
          window._displayedAdTaboolaHome = window._displayedAdTaboolaHome || { };
          window._taboola = window._taboola || [];
          _taboola.push({homepage:'auto'});
          !function (e, f, u, i) {
            if (!document.getElementById(i)){
              e.async = 1;
              e.src = u;
              e.id = i;
              f.parentNode.insertBefore(e, f);
            }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/ekanktechnologies-thisday/loader.js',
          'tb_loader_script');
          if(window.performance && typeof window.performance.mark == 'function')
            {window.performance.mark('tbl_ic');}
          `,
              }}
            />} */}
        </Head>
        <NextSeo
          title={
            SEOConstants.HOMETITLE + " - " + pageData.month + " " + pageData.day
          }
          description={SEOConstants.HOMEDESCRIPTION}
          additionalMetaTags={[
            {
              name: "keywords",
              content: SEOConstants.KEYWORDS,
            },
          ]}
          openGraph={{
            type: "article",
            url: "https://www.thisday.app/",
            title: SEOConstants.HOMETITLE,
            description: SEOConstants.HOMEDESCRIPTION,
            images: [
              {
                url: firstEventImage,
                secureUrl: firstEventImage,
                alt: "Og Image Alt",
              },
            ],
          }}
        />
        <div className="td-container max-width">
          <Grid container className={classes.mainGrid}>
            <contentContext.Provider value={pageData}>
              <Nav handleBottomsheet={handleBottomsheet} />
              {/* <DateDisplay /> */}
              {/* <WomensDayHomepageCard /> */}
              {/* <BhagatSingh/> */}
              {/* <Jalianwala/> */}
              <FeaturedEvent
                handleClose={handleClose}
                handleIndex={handleIndex}
                handleStoriesUrls={handleStoriesUrls}
                loadInfinite={false}

              />
              {close ? <Stories activeIndx={activeIndx} close={close} handleClose={handleClose} storiesUrls={storiesUrls} /> : null}
              {/* {close ? (
                <FeaturedEventStories
                  handleClose={handleClose}
                  close={close}
                  activeIndx={activeIndx}
                />
              ) : null} */}
              {/* <Separator /> */}
              {/* <RoyaltyInPeace/> */}
              {/* <YouTubeCard /> */}
              {AdHome(utmSource)}
              <MainEvent isFeatured={isFeatured} />
              <ChooseLocation />
              <OtherEvents isFeatured={isFeatured} />
              <InfiniteFetch />
              <PastEventsCard handleBottomsheet={handleBottomsheet} />
              <CategoryCard handleCategorySheet={handleCategorySheet} />
              <AnimatePresence exitBeforeEnter={true}>
                {showbottomsheet && (
                  <PastEventsBottomSheet
                    handleShimmer={handleShimmer}
                    handleBottomsheet={handleBottomsheet}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence exitBeforeEnter={true}>
                {showCategoryBottomSheet && (
                  <CategoryBottomSheet
                    handleShimmer={handleShimmer}
                    handleCategorySheet={handleCategorySheet}
                  />
                )}
              </AnimatePresence>
              <Strapline>
                <div className="content">
                  <p>History is best served daily, see you tomorrow!</p>
                </div>
              </Strapline>
              <Footer />
            </contentContext.Provider>
          </Grid>
          {selectedDate && (
            <Link
              style={{ display: "none" }}
              href={`/en/${monthMap[selectedDate.getMonth() + 1]
                }/${("0" + selectedDate.getDate()).slice(-2)}`}
            >
              <a ref={dateLink}></a>
            </Link>
          )}
        </div>
        {/* </motion.div> */}
        <div
          onClick={() => {
            setShowDatePicker(true);
            ReactGA.event(
              {
                category: "Birthday",
                action: "Viewed",
                label: "Home",
              },
              ["opera"]
            );
          }}
          className="birthDayFloat"
        >
          <div className="birthDayFloat--inner">
            <div className="birthDayFloat--img">
              <img src="/chronicles/images/birthdaycake.png" />
            </div>
            <div className={`birthDayFloat--text ${!isTop ? "d-none" : ""}`}>
              <span className="smallText">Know What Happened On</span>
              <h5>Your Birthday</h5>
            </div>
          </div>
        </div>
        <MobileDatePicker
          initialTime={selectedDate}
          isOpen={showDatePicker}
          onSelect={(date) => {
            ReactGA.event(
              {
                category: "BirthdayCard",
                action: "Selected",
                label: "Home",
              },
              ["opera"]
            );
            setSelectedDate(date);
            // const customUTMString = `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=birthdaycard`
            // router.push(`/en/${monthMap[date.getMonth() + 1]}/${date.getDate()}` + customUTMString)
          }}
          onCancel={() => setShowDatePicker(false)}
        />
      </>
    );
  } else {
    return (
      <>
        <NextSeo
          title={SEOConstants.HOMETITLE}
          description={SEOConstants.HOMEDESCRIPTION}
          keywords={SEOConstants.KEYWORDS}
        />
      </>
    );
  }
}

export async function getStaticPaths() {

  // var months = [
  //   'January', 'February', 'March', 'April', 'May',
  //   'June', 'July', 'August', 'September',
  //   'October', 'November', 'December'
  // ];

  // we will add months as and when the data is added
  var months;

  if (process.env.MINIMAL_BUILD) {
    months = [
      process.env.MINIMAL_BUILD_MONTH
    ];
  } else {
    months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
  }




  var paths = []
  for (var i = 0; i < months.length; i++) {
    for (var day = 1; day <= 31; day++) {
      if (day < 10) {
        day = '0' + day
      }
      var path = {
        // { params: { locale: 'en', month: months[i], day: String(day) } },
        params: { locale: 'en', month: months[i], day: String(day) }
      }
      paths.push(path)
      path = {
        // { params: { locale: 'en', month: months[i], day: String(day) } },
        params: { locale: 'hi', month: months[i], day: String(day) }
      }
      //commenting hindi
      // paths.push(path)
    }
  }

  //fetching current date data in order to save unwanted google translate API hits
  path = {
    // { params: { locale: 'en', month: months[i], day: String(day) } },
    params: { locale: "hi", month: "February", day: "08" },
  };
  if (!fs.existsSync("data.json")) {
    console.log('Fetching events inside [day].js');
    var res;
    if (process.env.MINIMAL_BUILD) {
      res = await Events.get("/events?months.month=" + process.env.MINIMAL_BUILD_MONTH + "&_limit=-1");
    } else {
      res = await Events.get("/events?_limit=-1");
    }
    const events = await res.data;
    fs.writeFileSync("data.json", JSON.stringify(events));
  }

  // paths.push(path)

  return {
    fallback: false,
    paths,
  };
}

export async function getStaticProps(context) {
  // console.log('path data == >> ' + JSON.stringify(context))
  let rawContent = [];
  var params = context.params;
  // console.log('getStaticProps ==>> ', JSON.stringify(params))
  rawContent = JSON.parse(fs.readFileSync("data.json", "utf8"));

  var content = rawContent.filter((item) => {
    return ((item.months.month === params.month) && (item.day.days === parseInt(params.day)));
  });

  // var response = await Events.get(
  //   "/events?months.month=" + params.month + "&day=" + params.day + "&_limit=-1"
  // );
  // var content = await response.data;
  // console.log('Building page for == >> ' + '==month==' + params.month + '==day==' + params.day)
  // console.log('Content in days ==>> ', JSON.stringify(content))

  if (params.locale == 'hi') {
    var tth = require('../../../translate_translate_text');

    var obj = content.map((item, index) => {
      // Adding new key in JSON that will be used to generate detail page link, since we modified original title to hindi
      item['event_title_slug'] = item.event_title;
      var val = tth.translate_to_hindi([item.event_title, item.event_summary]);
      return val;
    })

    var result = await Promise.all(obj)

    content.map((item, index) => {
      item.event_title = String(result[index][0]);
      item.event_summary = String(result[index][1]);
    });
  } else {
    content.map((item, index) => {
      // Adding new key in JSON that will be used to generate detail page link, since we modified original title to hindi
      item["event_title_slug"] = item.event_title;
    });
  }
  const requiredKeysCotnent = [
    "id",
    "cover_image",
    "event_date",
    "event_summary",
    "event_themes",
    "event_title",
    "event_title_slug",
    "featured",
    "event_types",
  ];
  let filtercontent = contentTrim(requiredKeysCotnent, content);

  var stories = [];
  if (!fs.existsSync("stories.json")) {
    console.log("fetching stories per day for the day ==>> " + params.day);
    var storiesResponse = await Events.get(
      "/stories?month.month=" + params.month + "&day=" + params.day + "&_limit=-1"
    );
    stories = await storiesResponse.data;
  } else {
    var rawStories = JSON.parse(fs.readFileSync('stories.json', 'utf8'));
    stories = rawStories.filter((item) => {
      return ((item.month.month === params.month) && (item.day.days === parseInt(params.day)));
    });
  }




  stories.map((item, index) => {
    delete item["event"]["event_body"];
    delete item["event"]["event_summary"];
    // console.log(JSON.stringify(item));
  });
  // console.log(JSON.stringify(stories));
  const requiredKeysStories = ["event", "title", "id"];
  let filterstories = contentTrim(requiredKeysStories, stories);

  //removing unsed data in json
  filterstories = filterstories.map(item => {
    return {
      ...item,
      event: {
        cover_image: item.event.cover_image?.formats?.thumbnail?.url || item.event.cover_image?.url || null
      }
    }
  })
  filtercontent = filtercontent.map(item => {
    let event_summary = item.event_summary;
    // if (item.featured) event_summary = item.event_summary;
    return {
      ...item,
      event_summary: event_summary,
      cover_image: {
        alternativeText: item.cover_image?.alternativeText || " ",
        url: item.cover_image?.formats?.small?.url || item.cover_image?.formats?.medium?.url || item.cover_image?.url || null,
        id: item.id
      }
    }
  })
  var pageData = {
    content: filtercontent,
    locale: params.locale,
    stories: filterstories,
    month: params.month,
    day: params.day,
  };

  return { props: { pageData, key: params.day + params.month } };

}
