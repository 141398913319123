import { useState, useEffect } from "react";

export function useScrollPosition() {
  const [isBottom, setIsBottom] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("none");
  const handleScroll = (event) => {
    const container = event.target.body;
    const { clientHeight, scrollHeight, scrollY: scrollTop } = container;
    setIsBottom(clientHeight + scrollY >= scrollHeight);
    setIsTop(scrollY <= 1000);
    if (scrollY > prevScrollY) {
      setScrollDirection("downward");
    } else if (scrollY < prevScrollY) {
      setScrollDirection("upward");
    } else {
      setScrollDirection("none");
    }
    setPrevScrollY(scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return { prevScrollY, isBottom, isTop, scrollDirection };
}

export default useScrollPosition;
