import React, { useState } from "react";
import DatePicker from "react-mobile-datepicker";
export const monthMap = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };
const MobileDatePicker = (props) => {
  // const [time, setTime] = useState(props.initialTime || new Date()); // initialize the time state with the provided initialTime prop or the current time
  const [isOpen, setIsOpen] = useState(props.isOpen || false); // initialize the isOpen state with the provided isOpen prop or false
  const dateConfig = {
    month: {
      format: (value) => monthMap[value.getMonth() + 1],
      caption: "Mon",
      step: 1,
    },
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
  };
  const handleSelect = (selectedTime) => {
    // setTime(selectedTime);
    setIsOpen(false);
    if (props.onSelect) {
      props.onSelect(selectedTime);
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };
  return (
    <DatePicker
      value={props.initialTime || new Date()}
      isOpen={isOpen}
      theme={props.theme || "light"}
      onSelect={handleSelect}
      onCancel={handleCancel}
      confirmText="Done"
      cancelText="Cancel"
      dateConfig={dateConfig}
      headerFormat={"MMMM-DD"}
      {...props} // spread the remaining props to the DatePicker component
    />
  );
};
export default MobileDatePicker;