import React from 'react'
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Link from 'next/link';

import dayjs from 'dayjs'
import { slugifyContent } from '../../../../src/Utility';
import { motion } from 'framer-motion';
import useGetUTM from '../../../customHooks/useGetUTM';
import { useRouter } from 'next/router';

const Container = styled.div`
/* height : 158px; */
margin-bottom : 20px;
border-bottom : 1px solid rgba(0, 0, 0, 0.09);
transition : all .15s ease;
:active{
    filter : opacity(80%);
    transform : scale(.98)
}
`
const Wrapper = styled.div`
display: flex;
height : 127px;
padding-bottom: 10px;
justify-content: flex-start;
align-items: stretch;
.img-wrapper{
    flex-basis: ${props => props.image ? "29.5%" : '0%'};
      .img{
          height : 100%;
          width : 100%;
          object-fit : cover;
          border-radius : 8px;
      }
      .lazy-load-image-background.blur.lazy-load-image-loaded{
    height : 100%;
    width: 100%;
}
  }
.body{
  flex-basis: ${props => props.image ? "calc(100% - 29.5%)" : '100%'};
  padding-left: 13px;
  .title-text{
      margin : 0;
  }
  .title{
      margin: 0;
      font-size: 14px;
      font-family: var(--heading-font);
      display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden
  }
  .summary{
      font-size: 12.5px;
      color: rgba(0,0,0,.6);
      margin: 0;
     display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp : 3;
    overflow: hidden; 
    margin-top: 6px;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    

  }
}

`
const OtherEvent = ({ item, locale }) => {
    let image = item.cover_image?.formats?.small?.url || item.cover_image?.url || null;
    const eventYear = dayjs(item.event_date).year();

    const title = item.event_title;
    const titleForSlug = item?.event_title_slug || title;
    const summary = item.event_summary;

    const link = slugifyContent(titleForSlug);
    const { utmAppendString } = useGetUTM()

    var titletxt;
    try {
        titletxt = item.event_types[0].Title;
        if (titletxt == 'Event') {
            titletxt = 'HAPPENED IN'
        } else if (titletxt == 'Birth') {
            titletxt = "BORN IN"
        } else if (titletxt == 'Death') {
            titletxt = "DIED IN"
        }
    } catch {
    }
    const router = useRouter();
    return (
        <Container>
            <Link href={router.asPath.includes("oppo/category") ? `/oppo/details/${link}` + utmAppendString :
                (router.asPath.includes("realme/category") ? `/realme/details/${link}` + utmAppendString :
                    `/${locale}/details/${link}` + utmAppendString)} prefetch={false}>
                <a>
                    <Wrapper image={image}>
                        <div className="img-wrapper">
                            {image && <LazyLoadImage effect="blur" src={process.env.ENV_IMAGE_ROOT + image} className="img" />}
                        </div>
                        <div className="body">
                            <p className="title-text">
                                <span style={{ fontFamily: 'var(--text-font-montserrat)', fontSize: '10px', lineHeight: '24px', fontWeight: '500', textTransform: 'uppercase' }}> {titletxt} </span>
                                <span style={{ color: "var(--primary-color)", fontFamily: 'var(--heading-font)', fontSize: '12px', lineHeight: '24px', fontWeight: '600' }}>{eventYear}</span>
                            </p>
                            <h3 className="title">{title}</h3>
                            <p className="summary">{summary}</p>
                        </div>
                    </Wrapper>
                </a>
            </Link>
        </Container>
    )
}

export default React.memo(OtherEvent, (props, nextProps) => {
    if (props.item.id === nextProps.item.id) {
        return true;
    }
})

