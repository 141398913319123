import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Dialog } from '@material-ui/core';

const Stories = ({ close, handleClose, activeIndx, storiesUrls = [] }) => {
  const [ref, setRef] = useState(null) // pro gamer move to force re render when ref becomes not null, with regualr useRef we cant force re render on when ref changes to some not null value, here value is the dom element
  useEffect(() => {
    if (ref) {
      ref.addEventListener('ready', () => {
        console.log('Player is ready!!');
        ref.show(window.location.origin + storiesUrls[activeIndx].url, null, { animate: false })
      })
      ref.addEventListener("amp-story-player-close", () => {
        ref.pause();
        handleClose(false);
      });
    }
  }, [ref])

  return (
    <>
      <Head key={"storiesamp123"}>
        <script async src="https://cdn.ampproject.org/amp-story-player-v0.js"></script>
      </Head>
      <Dialog fullScreen open={close ? close : false} style={{ backgroundColor: '#0003' }}>
        {/* <BackButton> Back </BackButton> */}
        <amp-story-player ref={e => setRef(e)} style={{ height: '100%', width: '100%' }}>
          <script type="application/json" dangerouslySetInnerHTML={{ __html: '{"display": {"attribution": "auto"},"controls": [{"name": "close","position": "end"}]}' }} />
          {/* rendereing link tags */}
          {storiesUrls.map((url, indx) => <a href={url.url} key={indx}>
            {/* <img data-amp-story-player-poster-img width="100%" height="100%" loading="lazy" src={process.env.ENV_IMAGE_ROOT + url.image} /> */}
          </a>)}

        </amp-story-player>

      </Dialog>
    </>
  )
}

export default Stories
