export const HOMETITLE = 'This Day in Indian History';
export const HOMEDESCRIPTION = 'Daily stories from Indian History, Art, Culture & Heritage.';
export const KEYWORDS = 'History, historic, date, India, Indian, Events, Birth date, death date, chronologically, datewise, this day in India History or Indian History'
    + 'today in history, anniversary, daily events, today’s historical events, today’s birthdays, born today, on this date, this day in history, this day in indian history';

export const REVIEWTITLE = 'Feedback';
export const REVIEWDESCRIPTION = 'Feedback Page';

export const OPPO_TITLE = 'ThisDay - Oppo';
export const REALME_TITLE = 'ThisDay - Realme';

export const TITLE_VISUAL_STORIES = 'Visual Stories';

export const HOME_PAGE_INFINITE_FETCH_LIMIT = 4;