import React from 'react'
import styled from 'styled-components';
import { useRouter } from 'next/router';

// import Image from 'next/image';
import DateDisplay from './DateDisplay';

// import { AppBar, Toolbar} from '@material-ui/core'
// import { makeStyles } from "@material-ui/core/styles";
import { dateContext } from "../../pages/_app";
import Link from "next/link";
// import useBaseUrl from "../../components/customHooks/useBaseUrl";
import useGetUTM from "../customHooks/useGetUTM";
import Voice from './Voice'

// const useStyles = makeStyles({
//   logoGrid: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-start",
//     padding: "25px 21px 0 21px",
//   },
// });
const ImageLogo = styled.img`
  height: 35px;
  width: 70px;
`;
const AppBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #ffff;
  width: 100%;
  min-height: 56px;
  padding: 10px 16px 0px 16px;
  z-index: 999;
`;
function Nav({ handleBottomsheet, hideDate = false }) {
  // const classes = useStyles();
  const router = useRouter();
  // const { originUrl } = useBaseUrl();

  const {
    // currentPageDate,
    handleCurrentPageDate,
    // currentPageMonth,
    handleCurrrentPageMonth,
  } = React.useContext(dateContext);

  //UTM
  const { urlString } = useGetUTM();
  React.useEffect(() => {
    handleCurrentPageDate(router.query.day);
    handleCurrrentPageMonth(router.query.month);
  });
  return (
    <>
      <AppBar>
        <Link href={urlString} shallow>
          <a>
            <ImageLogo src="/icons/Logo.svg" />
          </a>
        </Link>
        {!hideDate && <DateDisplay handleBottomsheet={handleBottomsheet} />}
      </AppBar>
      <div className="tabs-c">
        <div
          className={`tabname ${
            !router.pathname?.includes?.("podcasts") && "active"
          }`}
        >
          <img width={17} height={17} src="/podcasts/images/Home.svg" />
          &nbsp;
          <Link href={urlString}>
            <a style={{ fontSize: 17 }}>Home</a>
          </Link>
        </div>
        <div
          className={`tabname ${
            router.pathname?.includes?.("podcasts") && "active"
          }`}
        >
          <Voice />
          <Link href="/podcasts">
            <a style={{ fontSize: 17 }}>Podcasts</a>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Nav;
