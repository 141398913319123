import styled from 'styled-components';

// import Image from 'next/image';

import { useRouter } from 'next/router';

import Link from 'next/link'
import { slugifyContent } from '../../../src/Utility';


const CirclContainer = styled.div`
display : inline-block;

background-color:#fafafa;
border:1px solid #A61D29;    
height:60px;
width:60px;
border-radius:50%;
-moz-border-radius:50%;
-webkit-border-radius:50%;

.circle_img{ 
    margin : 4px;
    border-radius : 25px;
    height : 50px;
    width : 50px;
    object-fit: cover;
}
 /* .border_circle{
    background-color:#fff;
    border:#A61D29;    
     height:60px;
     width:60px;
     border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
 } */
:active{
    transform : scale(.97)
}
`
const FeaturedEventCircle = ({ src, index, handleClose, handleIndex, link }) => {
    const router = useRouter();
    return (
        <CirclContainer>
            {/* <a href={link} > */}


            <Link href={link} prefetch={false}>
                <a>
                    <img src={process.env.ENV_IMAGE_ROOT + src} className="circle_img" />
                </a>
            </Link>
            {/* </a> */}
            <div className="border_circle" >

            </div>
        </CirclContainer>
    )
}

export default FeaturedEventCircle
