import React from 'react'
import styled from 'styled-components';
import Head from 'next/head';
import dayjs from 'dayjs';
import { slugifyContent } from "../../../src/Utility";
const OtherEventContainer = styled.div`
padding: 10px 17px 17px 21px;
flex: 1;
flex-basis: 100%;
a{
    text-decoration: none;
}
.card{
    /* height : 158px; */
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0,0,0,0.09);
    margin-top: 20px;
}
.wrapper{
    display: flex;
    align-items: stretch;
    justify-content: center;
}
.img-wrapper{
    flex-basis: 33%;
    overflow: hidden;
}
.content-wrapper{
    flex-basis: 67%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    margin-left: 8px;
    font-family: 'Montserrat', sans-serif;
}
.content-wrapper p {
    margin: 0;
}
.content-wrapper .title{
 
    font-size: 16px;
    font-weight: 600;
    line-height: 19.6px;
}
.content-wrapper .happened-in{
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
    text-transform: uppercase;
}
.content-wrapper .happened-in span{
color : #A61D29;
font-weight: 600;
}
.content-wrapper .theme p span{
    font-size: 10px;
    padding: 4.5px;
    background-color: #F5F5F5;
    text-transform: uppercase;
    font-weight: 500;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    line-height: 19px;
}
amp-img{
    border-radius: 8px;
    /* object-fit: cover; */
}
`
const OtherEventCarusel = ({ pageData }) => {
    const content = pageData.content;
    const formatedContent = content.map((item, indx) => {
        let image, width, height;
        if (item.cover_image) {
            if (item.cover_image.formats && item.cover_image.formats.medium && item.cover_image.formats.medium.url) {
                image = item.cover_image.formats.medium.url;
                height = item.cover_image.formats.medium.height;
                width = item.cover_image.formats.medium.width;
            } else {
                image = item.cover_image.url;
                height = item.cover_image.height;
                width = item.cover_image.width;
            }

        }
        const eventYear = dayjs(item.event_date).year();
        const title = item.event_title;
        const titleForSlug = item.event_title_slug;

        const eventThemes = item.event_themes.map(item => item.theme);
        var eventType;
        try {
            eventType = item.event_types[0].Title;
            if (eventType == 'Event') {
                eventType = 'HAPPENED IN'
            } else if (eventType == 'Birth') {
                eventType = "BORN IN"
            } else if (eventType == 'Death') {
                eventType = "DIED IN"
            }
        } catch {
        }

        const handleThemes = () => {
            return eventThemes.map((item, index) => {
                if (index < eventThemes.length - 1) {
                    return item + ", ";
                }
                else {
                    return item;
                }
            })
        }
        const themes = handleThemes();
        return { image, title, eventYear, eventType, themes, featured: item.featured, summary: item.event_summary }
    })
    return (
        <>
            <Head>

            </Head>
            <OtherEventContainer>
                {
                    formatedContent.map((item, indx) => !item.featured && <a href={`/en/details/${slugifyContent(item.title)}.amp.html`} key={indx}><div className="card">
                        <div className="wrapper">
                            <div className="img-wrapper">
                                <amp-img layout="responsive" height="148" width="134" src={process.env.ENV_IMAGE_ROOT + item.image} />
                            </div>
                            <div className="content-wrapper">
                                <div className="happened-in"><p>{item.eventType} <span>{item.eventYear}</span></p></div>
                                <div className="title"><p>{item.title}</p></div>
                                <div className="theme"><p><span>{item.themes}</span></p></div>
                            </div>
                        </div>
                    </div></a>)
                }
            </OtherEventContainer>
        </>
    )
}

export default OtherEventCarusel
