import React from 'react'
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GoCalendar } from 'react-icons/go';

const useStyles = makeStyles({
    pasEventsGrid: {
        padding: '9px 14px 31px 17px',

    }
})

const CardContainer = styled.div`
box-shadow: 0px 0px 21px rgba(145, 145, 145, 0.25);
border-radius : 10px;
/* background-color : #fff; */
height : 72px;
display : flex;
justify-content : center;
align-items : center;
font-weight : 600;
font-family : var( --heading-font);
transition :all .2s ease ;
:active{
    transform : scale(.97);
    filter : brightness(90%)
}
`

const PastEventsCard = ({ handleBottomsheet }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.pasEventsGrid} >
            <CardContainer onClick={() => { handleBottomsheet(true) }}>
                {/* <span><GoCalendar style={{ color: 'var(--primary-color)', fontSize: '21.6px', marginRight: '5px' }} /></span> */}
                <span style={{ fontSize: "18px" }}>Check events by Dates</span>
            </CardContainer>
        </Grid>
    )
}

export default PastEventsCard
