import React, { useState, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Event from '../../cms/Event';
import { dateContext } from "../../../pages/_app"
import { HOME_PAGE_INFINITE_FETCH_LIMIT } from '../../../src/Constants'
import OtherEvent from './../OtherEvents/newUi/OtherEvent'
import PageLoading from '../../cssAnimations/page-loading/PageLoading'
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { TaboolaHome } from '../../../src/AdsUnit';

const Container = styled.section`
width: 100%;
padding: 17px;
.watcher{
    align-self: flex-end;
    height: 10px;
    width: 100%;
}
`
const NextUp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 8px;
  position: relative;
  /* width: 100%; */
  max-width: 600px;
  position: relative;
  span {
    background-color: #ffff;
    padding: 0 5px;
    font-weight: 500;
    font-size: 16px;
    font-family: var(--heading-font);
    position: relative;
    z-index: 1;
  }
  ::after {
    position: absolute;
    z-index: 0;
    content: "";
    width: 90%;
    height: 1px;
    background-color: rgba(236, 95, 95, 1);
  }
`;


const InfiniteFetch = () => {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  // usecontext to fetch the current/selected date;
  // @ts-ignore
  const { currentPageDate, currentPageMonth } = useContext(dateContext)



  // 
  const [currentmonth, setCurrentMonth] = useState<string>();
  const [day, setDay] = useState<number>();
  const [data, setData] = useState<Object[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const router = useRouter();

  let adunit = 0;


  // side effect so tht day state initialises properly 
  useEffect(() => {

    setDay(currentPageDate - 1)

  }, [currentPageDate])
  // side effect so tht month state initialises properly
  useEffect(() => {
    setCurrentMonth(currentPageMonth)
  }, [currentPageMonth])


  const contRef = React.useRef(null);


  //    watch when date goes from 1 to 0 , then  precede month and change date to 30/31 depending on the month
  useEffect(() => {
    if (day < 1) {

      let monthNumber = MONTHS.findIndex(item => item === currentmonth);
      if (monthNumber === 0) {
        monthNumber = 12
      }
      const newday = dayjs().month(monthNumber - 1).daysInMonth();

      setDay(newday);
      setCurrentMonth(MONTHS[monthNumber - 1])

    }
  })
  useEffect(() => {

    const observer = new IntersectionObserver(async (entries) => {
      const item = entries[0];
      if (item.isIntersecting && !loading && counter < HOME_PAGE_INFINITE_FETCH_LIMIT) {

        setLoading(true);
        const response = await Event.get("/events?months.month=" + currentmonth + "&_limit=-1&day.days=" + day);
        const resdata = [`${currentmonth} ${day}`, ...await response.data]; //adding template string  to mark start iof new fetch with new date
        setData([...data, ...resdata])
        setDay(day - 1);
        setCounter(counter + 1);
        setLoading(false);



      }
    }, {
      threshold: 1.0
    })

    if (contRef.current) {
      observer.observe(contRef.current)

      return () => {
        if (contRef.current !== null)
          observer.unobserve(contRef.current)
      }
    }


  }, [contRef, day, currentmonth, counter, loading])

  return (
    <Container key={router.asPath}>

      {

        data.map((item, indx) => {

          if (typeof item === "string") return <NextUp key={indx}>
            <span>{item}</span>
          </NextUp>
            ;

          if (adunit % 3 === 0) {
            // console.log("adding taboola home widget " + adunit);
            adunit++;
            return <div key={adunit}>
              {/* {TaboolaHome(adunit)} */}
              <div style={{ marginTop: '20px' }}>
                <OtherEvent
                  // @ts-ignore
                  key={item.id}
                  item={item}
                  locale="en" />
              </div>
            </div>
          } else {
            adunit++;
            return <OtherEvent
              // @ts-ignore
              key={item.id}
              item={item}
              locale="en" />
          }
        })
      }
      {loading && <PageLoading />}
      <div ref={contRef} className='watcher'></div>
    </Container>
  )
}

export default InfiniteFetch
